#more-details {
  article {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }

  a {
    color: white;
  }
}

#more-details button {
  text-transform: capitalize;
  background-color: var(--ted-red);
  color: white;
  padding: 0.8rem 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-family: var(--ted-font);
  font-size: 1rem;
  cursor: pointer;
}

#more-details {
  background-color: var(--ted-black);
  color: white;
  padding: 5rem 0;
  display: grid;
  place-items: center;
  font-family: var(--ted-font);
  text-transform: uppercase;
}

@media screen and (min-width: 568px) {
  #more-details {
    article {
      font-size: 3rem;
      margin-bottom: 1rem;

      p {
        font-size: 5rem;
      }
    }
  }
}

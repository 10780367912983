#about-tedx > * {
  margin-bottom: 1rem;
}

#about-tedx {
  padding: 3rem 1rem;
  display: grid;
  place-items: center;
  font-family: var(--ted-font);
}

#about-tedx p {
  font-weight: 500;
  font-size: 2rem;
}

#about-tedx p span {
  color: var(--ted-red);
}

#about-tedx article {
  border: 0.1rem solid #ddd;
  padding: 2rem;
  font-size: 1.1rem;
  text-align: center;
  font-family: var(--ted-font);
  font-weight: 500;
}

#about-tedx button {
  text-transform: capitalize;
  background-color: var(--ted-red);
  color: white;
  padding: 0.8rem 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-family: var(--ted-font);
  font-size: 1rem;
  cursor: pointer;
}

@media screen and (min-width: 568px) {
  #about-tedx > * {
    margin-bottom: 3rem;
  }

  #about-tedx {
    padding: 5rem 7rem;
    display: grid;
    place-items: center;
    font-family: var(--ted-font);
  }

  #about-tedx p {
    font-weight: 500;
    font-size: 2rem;
  }

  #about-tedx article {
    border: 0.1rem solid #ddd;
    padding: 8rem;
    font-size: 1.2rem;
    text-align: center;
    font-family: var(--ted-font);
    font-weight: 500;
  }
}

#past-events {
  padding: 1rem 2rem;
  background-color: #f9f9f9;

  img {
    width: 100%;
  }

  .image-frame {
    width: fit-content;
    border: 0.1rem solid #ddd;
    display: flex;
    flex-flow: column;
    align-items: center;
    button {
      width: 10rem;
      background-color: var(--ted-red);
      font-family: var(--ted-font);
      padding: 0.7rem 0.5rem;
      border-radius: 1.2rem;
      font-weight: 800;
      font-size: 1.1rem;
      margin: 1.2rem 0;
    }
  }

  a {
    color: black;
  }

  p {
    text-align: center;
    font-family: var(--ted-font);
    font-weight: 500;
    font-size: 2rem;
    span {
      color: var(--ted-red);
    }
  }
}

@media screen and (min-width: 568px) {
  #past-events {
    padding: 3rem 5rem;
    background-color: #f9f9f9;
    img {
      width: 30rem;
    }

    p {
      font-size: 3rem;
    }
  }
}

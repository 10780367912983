header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
    width: 10rem;
  }
  nav.desktop {
    display: none;
  }
}

/* Small (sm) devices (landscape phones, 567px and up) */
@media screen and (min-width: 568px) {
  .hamburger {
    display: none;
  }

  header {
    font-family: var(--ted-font);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;

    nav.desktop {
      display: block;
    }
  }

  header a {
    color: var(--ted-black);
    font-weight: 500;
    transition: color 0.1s linear;
    font-size: 1.2rem;
    margin: 0 1rem;
  }

  header a:hover {
    color: var(--ted-red);
  }

  header img {
    width: 10rem;
  }

  /* tedx tagline */

  article {
    font-family: Arial;
    line-height: 1;
  }

  article section {
    text-align: left;
    color: var(--ted-red);
    font-weight: 800;
  }

  article h1 {
    font-weight: 400;
  }

  article small {
    font-weight: bold;
  }

  article small span {
    color: var(--ted-red);
  }
}

/* Medium (md) devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
}

/* Large (lg) devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
}

/* Extra (xl) devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
}

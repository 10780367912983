*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --ted-red: #e62b1e;
  --ted-white: #fff;
  --ted-black: #000;
  --ted-font: "Montserrat";
}

body {
  font-family: var(--tedx-font), sans-serif;
  width: 100vw;
  overflow-x: hidden;
}

button {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}

/* CUSTOM SCROLL BAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

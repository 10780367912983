#about-us {
  width: 100vw;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .stage {
    position: relative;
    z-index: 1;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-weight: 600;
    }

    span {
      color: var(--ted-red);
    }
  }

  img {
    width: 100%;
    filter: brightness(0.8);
  }

  .content {
    p {
      font-weight: 500;
      margin: 1rem 0;
    }

    article {
      font-weight: 450;
    }
  }

  p {
    font-size: 2rem;
    font-family: var(--ted-font);
    font-weight: 400;
  }

  .content {
    padding: 1.8rem;
    font-family: var(--ted-font);
  }

  hr {
    background-color: black;
    margin: 4rem 0;
    border: 0.1rem solid black;
  }
}

@media screen and (min-width: 568px) {
  #about-us {
    .content {
      article {
        padding: 0 9rem;
        font-size: 120%;
        line-height: 1.3;
      }
    }

    hr {
      width: 50%;
      margin: 4rem auto;
    }
  }
}

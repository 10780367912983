#sponsors .banner {
  background-color: var(--ted-black);
  color: white;
  font-size: 3rem;
  padding: 2rem;
  text-align: center;
  font-family: var(--ted-font);
}

#sponsors {
  img {
    width: 30%;
  }
  .sponsors_div {
    display: grid;
    grid-template-columns: 1fr;
  }

  .sponsor {
    display: flex;
    flex-flow: column;
    justify-content: end;
    align-items: center;
    font-family: var(--ted-font);
    padding: 1rem;

    p {
      font-weight: 600;
      margin-top: 1.4rem;
    }
  }
}

@media screen and (min-width: 768px) {
  #sponsors {
    .sponsors_div {
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }
  }
}

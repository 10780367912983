footer {
  font-family: var(--ted-font);

  a {
    color: var(--ted-black);
  }
}

footer img {
  width: 8rem;
}

footer section:nth-child(2) {
  color: var(--ted-black);
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  i {
    // margin: 0 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover {
      color: var(--ted-red);
    }
  }
}

footer section:first-child {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  padding: 1.8rem 1rem;

  & > div {
    font-weight: 500;
  }

  & a {
    margin: 0 0.4rem;
    color: #000;
    font-size: 0.8rem;
    &:hover {
      color: var(--ted-red);
    }
  }
}

// social media icons
footer section:nth-child(2) {
  color: var(--ted-black);
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  i {
    margin: 0 1rem;
    font-size: 1.25rem;
    cursor: pointer;
    &:hover {
      color: var(--ted-red);
    }
  }
}

// copyright
footer section:nth-child(3) {
  text-align: center;
  margin: 1rem;
  font-weight: 550;
  font-size: 0.6rem;
}

@media screen and (min-width: 568px) {
  footer img {
    width: 15rem;
  }

  footer section:first-child {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 2rem;

    & > div {
      font-weight: 500;
    }

    & a {
      margin: 0 1rem;
      color: #000;
      font-size: 1.2rem;
      &:hover {
        color: var(--ted-red);
      }
    }
  }

  // social media icons
  footer section:nth-child(2) {
    color: var(--ted-black);
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;

    i {
      margin: 0 1rem;
      font-size: 2rem;
      cursor: pointer;
      &:hover {
        color: var(--ted-red);
      }
    }
  }

  // copyright
  footer section:nth-child(3) {
    text-align: center;
    margin: 1rem 0;
    font-weight: 500;
    font-size: 1.2rem;
  }
}

.sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  font-family: var(--ted-font);
  display: flex;
  z-index: 999;

  .nav {
    width: 70vw;
    background-color: white;
  }

  .overlay {
    width: 30vw;
    background-color: rgba(13, 12, 11, 0.8);
  }

  nav {
    display: flex;
    flex-flow: column;
  }

  a {
    color: var(--ted-black);
    font-weight: 500;
    transition: color 0.1s linear;
    font-size: 1.2rem;
    margin: 1rem;
  }

  svg {
    margin: 1rem;
  }
}

.sidebar.close {
  animation: close 0.2s linear forwards;
}

.sidebar.open {
  animation: open 0.1s linear forwards;
}

@keyframes close {
  from {
    left: 0;
  }
  to {
    left: -100vw;
  }
}

@keyframes open {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}

@media screen and (min-width: 568px) {
  .sidebar {
    display: none;
  }
}

#speakers {
  .image-banner {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .speakers-header {
    font-family: var(--ted-font);
    text-align: center;
    margin: 2rem 0;
  }

  .speakers-list {
    display: flex;
    flex-flow: column;
    font-family: var(--ted-font);
    padding: 0 1rem;

    .speaker {
      margin-bottom: 1rem;
      padding: 1rem;
      border: 0.1rem solid #ddd;

      h2 {
        // margin-bottom: 0.1rem;
      }

      article {
        font-size: 110%;
        line-height: 1.3;
      }
    }

    img {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
}

@media screen and (min-width: 568px) {
  #speakers {
    .speakers-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0 1rem;
      padding: 0 1rem;
    }
    .speaker {
      article {
        padding: 1rem;
      }
      h2 {
        padding: 0 1rem 0.1rem;
      }
    }
  }
}

#home img {
  width: 100vw;
  display: block;
}

main img {
  width: 100%;
  height: auto;
}

main img:after {
  margin: 0;
  padding: 0;
}

main img:before {
  margin: 0;
  padding: 0;
}

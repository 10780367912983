#members {
  width: 100vw;
  .image-banner {
    width: 100%;
    img {
      width: 100%;
      //   height: auto;
    }
  }

  .team-year {
    text-align: center;
    margin: 2rem 0;
    font-family: var(--ted-font);
    font-weight: 500;

    span {
      color: var(--ted-red);
    }
  }

  .people {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .member {
    padding: 1rem;
    .name {
      font-family: var(--ted-font);
      text-align: center;
      font-weight: 500;
    }
    .team {
      font-family: var(--ted-font);
      text-align: center;
    }
    img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 568px) {
  #members {
    .people {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 auto;
    }

    .member {
      img {
        width: 30vw;
      }
    }
  }
}
